.loader_ldsRing__A_lQV {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
}
.loader_ldsRingDiv__Z82LT {
    -moz-box-sizing: border-box;
         box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 8px;

    border-radius: 50%;
    -webkit-animation: loader_lds-ring__kKndi 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
       -moz-animation: loader_lds-ring__kKndi 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
            animation: loader_lds-ring__kKndi 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

}

.loader_ldsRingDivDefault__SSi2_ {
    border: 2px solid #382216;
    border-color: #382216 transparent transparent transparent;
}

.loader_ldsRingDivWhite__D_dhb {
    border: 2px solid #382216;
    border-color: #382216 transparent transparent transparent;
}

.loader_ldsRingDiv__Z82LT:nth-child(1) {
    -webkit-animation-delay: -0.45s;
       -moz-animation-delay: -0.45s;
            animation-delay: -0.45s;
}
.loader_ldsRingDiv__Z82LT:nth-child(2) {
    -webkit-animation-delay: -0.3s;
       -moz-animation-delay: -0.3s;
            animation-delay: -0.3s;
}
.loader_ldsRingDiv__Z82LT:nth-child(3) {
    -webkit-animation-delay: -0.15s;
       -moz-animation-delay: -0.15s;
            animation-delay: -0.15s;
}
@-webkit-keyframes loader_lds-ring__kKndi {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@-moz-keyframes loader_lds-ring__kKndi {
    0% {
        -moz-transform: rotate(0deg);
             transform: rotate(0deg);
    }
    100% {
        -moz-transform: rotate(360deg);
             transform: rotate(360deg);
    }
}
@keyframes loader_lds-ring__kKndi {
    0% {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@media screen and (max-width: 770px) and (min-width: 0) {
    .loader_ldsRing__A_lQV {
        width: 20px;
        height: 20px;
    }
    .loader_ldsRingDiv__Z82LT {
        width: 20px;
        height: 20px;
    }
}

.flashMessage_flashMessage__ftkFK {
    font-size: 18px;
    padding-bottom: 8px;
}

.flashMessage_flashMessageDanger__G_SoJ {
    color: #b44d81;
}

.flashMessage_flashMessageSuccess__Ps_UF {
    color: #4db485;
}

.flashMessage_flashMessageInfo__4YNr2 {
    color: #5e5cd9;
}
.hugo_hugo__g_foO {
}

.hugo_slider__LTerj {
 margin-top: 13px;
}

.hugo_displayNone__KMhsV {
 display: none;
}

.hugo_actions__d2Flu {
 margin-top: 40px!important;
}
/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px; }
    .owl-theme .owl-nav [class*='owl-']:hover {
      background: #869791;
      color: #FFF;
      text-decoration: none; }
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .owl-theme .owl-dots .owl-dot span {
      width: 10px;
      height: 10px;
      margin: 5px 7px;
      background: #D6D6D6;
      display: block;
      -webkit-backface-visibility: visible;
      -webkit-transition: opacity 200ms ease;
      -moz-transition: opacity 200ms ease;
      transition: opacity 200ms ease;
      border-radius: 30px; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #869791; }

