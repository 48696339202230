.flashMessage {
    font-size: 18px;
    padding-bottom: 8px;
}

.flashMessageDanger {
    color: #b44d81;
}

.flashMessageSuccess {
    color: #4db485;
}

.flashMessageInfo {
    color: #5e5cd9;
}