.ldsRing {
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
}
.ldsRingDiv {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 32px;
    height: 32px;
    margin: 8px;

    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

}

.ldsRingDivDefault {
    border: 2px solid #382216;
    border-color: #382216 transparent transparent transparent;
}

.ldsRingDivWhite {
    border: 2px solid #382216;
    border-color: #382216 transparent transparent transparent;
}

.ldsRingDiv:nth-child(1) {
    animation-delay: -0.45s;
}
.ldsRingDiv:nth-child(2) {
    animation-delay: -0.3s;
}
.ldsRingDiv:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 770px) and (min-width: 0) {
    .ldsRing {
        width: 20px;
        height: 20px;
    }
    .ldsRingDiv {
        width: 20px;
        height: 20px;
    }
}
