.hugo {
}

.slider {
 margin-top: 13px;
}

.displayNone {
 display: none;
}

.actions {
 margin-top: 40px!important;
}